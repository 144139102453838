.App {
  text-align: center;
  background-color: #F0F0F0;
  min-height: 100vh;
  height: auto;
  width: auto;
}

.content{
  display: flex;
  min-width: 100vh;
  max-height: 900px;
}

.bid{
  display: flex;
  max-width: 100%;
  width: available;
  justify-content: space-evenly;
}

.swap{
  border: 0;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  max-width: 600px;
  min-width: 300px;
  max-height: 1000px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .loader {
    animation: App-logo-spin infinite 2s linear;
  }
}

.abs-centered{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.relative{
  position: relative;
}

.region-scaffold:not(:last-child) {
  border-bottom: 1px solid rgba(38, 38, 38, 0.08);
}


.App-header {
  background-color: #F0F0F0;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: calc(10px + 2vmin);
  color: #606060;
}

.address{
  font-size: 14px;
  align-items: center;
  vertical-align:middle;
  background-color: #FFFFFF;
  color: #2c3136;
  border: 0;
}


.panel {
  border: 1px solid rgba(206,209,213,.4);
  border-radius: 8px;

  box-sizing: border-box;
  color: #2c3136;
  display: flex;
  flex-direction: column;
  max-height: 3000px;
  overflow: hidden;
  position: relative;
  transition: box-shadow .2s ease-out;
  margin: 0px 0px 20px;
  padding-bottom: 20px;
  padding-top: 8px;
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.16) 0px 8px 16px;
}

.panel:hover {
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.30) 1px 10px 36px;
}

.small-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align:middle;
}

.small-input {
  width: 70px;
  align:left;
}

.plus-icon{
  margin: 10px;
  color:green;
  float: right;
}

.plus-icon:hover{
  background-color: rgba(20, 143, 43, 0.08);
  border-radius: 50%;
}

.middle-content{
  padding: 20px 0px 0px 20px;
}

.row-container{
  display: flex;
  flex-direction: row;
}

.v-center{
  vertical-align:middle;
  align-items: center;
}

.bt-tile__title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  float: left;
  display: flex;
  vertical-align:middle;
  align-items: center;
}

.bt-tile__title:hover{
  color: #606060;
}

.rotate-0 {
  transform: rotate(0deg);
  transition-delay: 250ms;
}

.rotate-180 {
  transform: rotate(-180deg);
  transition-delay: 250ms;
}

.expander {
  margin: 0px -12px 0px -12px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align:middle;
  justify-content: space-between;
  min-height: 50px;
  max-height: 50px;
}
.expander:hover{
  background-color: #F0F0F0;
}

.product {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px;
  vertical-align:middle;
  justify-content: space-between;
  flex-grow: 100;
  width: available;
  align-items: stretch;
}
.product:hover{
  background-color: #F0F0F0;
}

.button{
  margin-top: 16px;
  display: block;
  position: absolute;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  transition: all .15s ease;
  text-decoration: none;
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.16) 0px 8px 16px;
}
.button:hover{
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.30) 1px 10px 36px;
 }

.small-button{
  margin-top: 1px;
  display: block;
  position: absolute;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  transition: all .15s ease;
  text-decoration: none;
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.16) 0px 8px 16px;
}
.small-button:hover{
  box-shadow: rgba(38, 38, 38, 0.04) 0px 1px 2px,
  rgba(38, 38, 38, 0.30) 1px 10px 36px;
}

.link{
  font-weight: bold;
  color: darkgreen;
}

.green{
  color:white;
  background-color: #148f2b;
}
.green:hover{
  background-color: #00AA00;
}

.orange{
  color:white;
  background-color: darkorange;
}
.orange:hover{
  background-color: orange;
}


.collapsed{
  overflow: hidden;
  max-height: 0px;
  -webkit-transition: max-height 0.3s;  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition:  max-height 0.3s;  /* Firefox 4-15 */
  -o-transition: max-height 0.3s;  /* Opera 10.50–12.00 */
  transition: max-height 0.3s;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
  display: flex;
  flex-direction: column;
}

.in{
  max-height: 3000px;
  -webkit-transition: max-height 0.5s ease-in;  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition:  max-height 0.5s ease-in;  /* Firefox 4-15 */
  -o-transition: max-height 0.5s ease-in;  /* Opera 10.50–12.00 */
  transition: max-height 0.5s ease-in;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

.left{
  left: 20px;
}

.right{
  right: 30px;
}

.small-text {
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  margin: 0px;
  padding: 0px;
  display: flex;
  -webkit-box-pack: justify;
  place-content: center space-between;
  color: rgba(38, 38, 38, 0.7);
  white-space: nowrap;
}

.products-name {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  vertical-align:middle;
}


.pointer{
  cursor: pointer;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

.region_left{
  flex-direction: column;
  min-width: 320px;
  margin: 0px 20px 0px;
  padding: 0px 0px 0px;
  padding-bottom: 20px;
}


.region_middle{
  flex-direction: column;
  min-width: 100vh;
  margin: 0px 20px 0px;
  padding: 0px 0px 0px;
}


.xs_12 {
  padding-left: 12px;
  padding-right: 12px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
